import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const LittleFoci = () => {
    const data = useStaticQuery(graphql`
    {
        allAirtable(filter: {table: {eq: "foci"}}) {
          edges {
            node {
              data {
                postMarkdown {
                  childMarkdownRemark {
                    html
                    timeToRead
                  }
                }
                slug
                title
                snippet
                image {
                  id
                  localFiles {
                    childImageSharp {
                      fluid(jpegProgressive: true, maxWidth: 100) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `)
return (
    <div className='little_reports'>
        {
            data.allAirtable.edges.map(focus =>  {
                const focusPath = `/lab_report/${focus.node.data.slug}`;
                return (
                    <Link 
                    to={focusPath}
                    key={focus.node.data.slug} >
                        <div key={focus.node.data.slug} className='focus' style={{
                            backgroundImage: `url(${focus.node.data.image.localFiles[0].childImageSharp.fluid.src})`
                        }}>
                            <div className='focus_title' dangerouslySetInnerHTML={{ __html: focus.node.data.title }} />
                        </div>
                    </Link>
                    )
            })
        }
    </div>
)}
    
    export default LittleFoci