import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostTags from "../components/PostTags/PostTags";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";
import LittleFoci from "../components/LittleFoci";
import Gallery from "../components/Gallery"

export default class PostTemplate extends React.Component {
  render() {
    console.log(this.props);

    const { data, pageContext } = this.props
    const { slug } = pageContext
    const postNode = data.airtable
    const post = postNode.data
    const author = post.author ? post.author[0].data : ""
    
    if (!post.id) {
      post.id = slug
    }
    
    if (!post.category) {
      post.category = config.postDefaultCategoryID
    }
    
    
    let headerLinks, titlePrefix
    if (postNode.table === 'foci') {
      headerLinks = <LittleFoci />      
    } else if (postNode.table === 'dev') {
      headerLinks = <h3 className="title-backlink"><Link to="/r&d">R&D </Link></h3>
    } else if (postNode.table === 'work') {
      headerLinks = <h3 className="title-backlink"><Link to="/projects">PROJECTS</Link></h3>
    } else if (postNode.table === 'lab_notes') {
      headerLinks = <h3 className="title-backlink"><Link to="/labnotes">LAB NOTES</Link></h3>
    }
     else {
      headerLinks = <div className="ghost" />
    }
    
    let gallery
    if (postNode.table === 'work' || postNode.table == 'dev') {
      const pics = post.image.localFiles
      gallery = <Gallery 
        pics = {pics}
        title = "Project Gallery"
      />
      
    } else {
      gallery = <div className="ghost" />
    }
    
    
    const PageHero = () => {
      if (post.image) {
        const imageUrl = post.image ? post.image.localFiles[0].childImageSharp.gatsbyImageData.images.fallback.src : ""
        return ( <div className='title_image' style={{
          backgroundImage: 'url(' + imageUrl + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height:'80vh' 
          }}>
          {headerLinks}
          <h1 className="title"><span className="title-prefix">{titlePrefix}</span>{post.title}</h1>
        </div>
        )
      } else {
        return ( <h1 className="title_no_image">{post.title}</h1> )
      }

    }
    return (
      <>
      <Helmet>
        <title>{`${post.title} | ${config.siteTitle}`}</title>
      </Helmet>
      <Layout>
        <div className="index-container">
          <SEO postPath={this.props.location.pathname} postNode={postNode} postSEO />
          <div>
            <PageHero />
            <div dangerouslySetInnerHTML={{ __html: post.postMarkdown.childMarkdownRemark.html }} />
            {author}
            <div className="post-meta">
              <PostTags tags={post.tags} />
            </div>
            { gallery }
          </div>
        </div>
      </Layout>
      </>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $dateFormat: String) {
    airtable(data: {slug: {eq: $slug}}) {
      table
      data {
        title
        slug
        date(formatString: $dateFormat)
        postMarkdown {
          childMarkdownRemark {
            html
            excerpt(format: PLAIN)
            timeToRead
          }
        }
        image {
          localFiles {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              fluid(jpegProgressive: true, maxWidth: 1500) {
                  src
              }
            }
          }
        }
      }
    }
  }
`;
